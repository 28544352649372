import React, { useEffect, useState } from 'react';
import { LocalStorageConstants } from '../../../constants/local-storage-constants';
import { strings } from '../../../locale/locale-properties';
import motorNetworkUtil from '../../../utils/network/motor/motor-util';
import responseUtils from '../../../utils/network/response-util';
import responseSetters from '../../../utils/response-setters/response-setters';
import SelectBoxUtils from '../../../utils/ui-comp/selectbox-utils';
import { TextViewUtils } from '../../../utils/ui-comp/text-view-utils';
import MotorAllNotification from '../motor/childcomp/MotorAllNotifications';
import { MotorDetails } from '../motor/childcomp/MotorDetails';
export default function DashboardDetails(props) {
    let [motorList, setMotorList] = useState([]);
    let [selectedMotor, setSelectedMotor] = useState(0);

    let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    // Initial call.
    function populateMotorDetails(response) {
        let list = [];
        if (responseUtils.validateResponse(response)) {
            let subList = [];
            let data = responseUtils.getSuccessResponse(response);
            for (let pos = 0; pos < data.length; pos++) {
                let motor = responseSetters.setMotorDetails(data[pos]);

                let arr = {};
                // console.log(motors[position]);
                arr['key'] = motor._motorId;
                arr['data'] = motor._motorName;
                arr['motor'] = motor;
                subList.push(arr);
            }
            list.push(subList);
            if(subList.length>0)
            {
                list.push({ 'selectedItem': subList[0]['key'] })
            }
            //   console.log(list)
            setMotorList(list);
        }
    }

    console.log('www');
    function onItemChange(key, val) {
        if (key === 'motorChange') {
            setSelectedMotor(val);
        }
    }
    let scheduleListComponent;
    let selectedItem;
    let motors = [];
    let currentMotor;
    if (motorList.length > 1) {
        if (selectedMotor == 0) {
            selectedItem = motorList[1].selectedItem;
        }
        else {
            selectedItem = selectedMotor;
        }
        motors = motorList[0];
        console.log(motors);
        for (let pos = 0; pos < motors.length; pos++) {
            if (motors[pos].key == selectedItem) {
                currentMotor = motors[pos].motor;
                break;
            }
        }
        console.log(currentMotor)
    }

    useEffect(function () {
        motorNetworkUtil.getAllMotors(populateMotorDetails);
        // setMotor(motor);
    }, []);

    return (
        <div style={{ width: "100%", height: "100%", padding: 10 }}>
            <div className="row reverse_row app_division" style={{ height: '100%' }}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="app_row_flex">

                        <TextViewUtils
                            className="app_small_text app_align_self_center app_margin_default_left"
                            text={strings.motor_name} />
                        <div className="app_align_self_center app_margin_default_left">
                            <SelectBoxUtils callbackWithKey='motorChange' onChangeValue={onItemChange} options={motors} />
                        </div>
                    </div>

                </div>
                <div
                    className="col-lg-6 col-md-12 col-sm-12"
                    style={{position: 'relative' }}>
                    <div className='app_margin_default_top'>
                        <MotorDetails motor={currentMotor} />
                    </div>

                </div>
                <div
                    className="col-lg-6 col-md-12 col-sm-12"
                    style={{ height: "100%" }}>
                    <div className='app_margin_top_10'>
                        <MotorAllNotification motorId={selectedItem} />
                    </div>
                </div>
            </div>
        </div>);
}
export class FarmerAppUsers
{
    get farmerAppUserId() {
        return this._farmerAppUserId;
    }

    set armerAppUserId(farmerAppUserId) {
        this._farmerAppUserId = farmerAppUserId;
    }

    get farmerEmail() {
        return this._farmerEmail;
    }

    set farmerEmail(farmerEmail) {
        this._farmerEmail = farmerEmail;
    }

    get farmerPhone() {
        return this._farmerPhone;
    }

    set farmerPhone(farmerPhone) {
        this._farmerPhone = farmerPhone;
    }

    get username() {
        return this._username;
    }

    set username(username) {
        this._username = username;
    }

    get farmerIMEI() {
        return this._farmerIMEI;
    }

    set farmerIMEI(farmerIMEI) {
        this._farmerIMEI = farmerIMEI;
    }

    get password() {
        return this._password;
    }

    set password(password) {
        this._password = password;
    }

    get privateKey() {
        return this._privateKey;
    }

    set privateKey(privateKey) {
        this._privateKey = privateKey;
    }

    get dateAdded() {
        return this._dateAdded;
    }

    set dateAdded(dateAdded) {
        this._dateAdded = dateAdded;
    }

    get lastModified() {
        return this._lastModified;
    }

    set lastModified(lastModified) {
        this._lastModified = lastModified;
    }

    get sessionActive() {
        return this._sessionActive;
    }



    set sessionActive(sessionActive) {
        this._sessionActive = sessionActive;
    }

    get active() {
        return this._active;
    }

    set active(active) {
        this._active = active;
    }

    get sessionKey() {
        return this._sessionKey;
    }

    set sessionKey(sessionKey) {
        this._sessionKey = sessionKey;
    }

    get status() {
        return this._status;
    }

    set status(status) {
        this._status = status;
    }

    get farmersFarmerId() {
        return this._farmersFarmerId;
    }

    set farmersFarmerId(farmersFarmerId) {
        this._farmersFarmerId = farmersFarmerId;
    }
}
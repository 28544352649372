import React, { useState } from "react";
import { strings } from "../../locale/locale-properties";
import "../../styles/Main.css";
import "./Login.css";
import { TextViewUtils } from "../../utils/ui-comp/text-view-utils";
import { ImageViewUtils } from "../../utils/ui-comp/img-view-utils";
import ajaxUtil from "../../utils/network/ajax-util";
import responseUtils from "../../utils/network/response-util";
import {Redirect} from 'react-router-dom'
import { OtpVerification } from "./otp/OtpVerification";
import { ErrorConstants } from "../../constants/error-constants";
import errorUtils from "../../utils/errors/error-util";
import { ResponseKeys } from "../../constants/response-keys";
import { MobileVerification } from "./mobile/MobileVerification";
import responseSetters from "../../utils/response-setters/response-setters";
import { FarmerAppUsersKeys } from "../../constants/farmer-keys/farmer-app-users-keys";
import { LocalStorageConstants } from "../../constants/local-storage-constants";
import { AlertConstants } from "../../constants/alert-constants";
export function Login(props) {

  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState(false);

  const [otpPassword, setOtpPassword] = useState("");
  const [farmerId, setFarmerId] = useState();
  const [farmerPhone, setFarmerPhone] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let componentDetails;

  if(localStorage.getItem(LocalStorageConstants.TOKEN) != null)
  {
    return <Redirect to="/home" push/>
  }

  async function login(evt)
  {
      evt.preventDefault();

      // Show progress bar
      props.showProgress();

      if(phoneNumber.length > 0)
      {
        let array = {};
        array[ResponseKeys.FARMER_PHONE] = phoneNumber;

         let result = await ajaxUtil.synAjax("api/auth/otp", "POST", array);
         
         if(responseUtils.validateResponse(result))
         {
            let data = responseUtils.getSuccessResponse(result);
            props.showAlert(result['message'], AlertConstants.SUCCESS);

            setSuccess(true);

            // setting farmer details
            setFarmerId(data[ResponseKeys.FARMER_USER_ID]);
            setFarmerPhone(data[ResponseKeys.FARMER_PHONE]);
         }
         else
         {
            let data = responseUtils.getFailureResponseCode(result);
            props.showAlert(errorUtils.getErrorMessage(data), AlertConstants.FAILED);
         }
      }
      else
      {
        props.showAlert(errorUtils.getErrorMessage(ErrorConstants.INVALID_USER), AlertConstants.FAILED);
      }
     // setSuccess(true);
      // Dismissing progress bar
      props.dismissProgress();

  }

  function timeout()
  {
    setSuccess(false);
  }

  async function verifyOtp(event)
  {
    event.preventDefault();
     // Show progress bar
     props.showProgress();

    let array = {};
    array[ResponseKeys.FARMER_PHONE] = farmerPhone;
    array[ResponseKeys.FARMER_USER_ID] = farmerId;
    array['otp'] = otpPassword;

    let result = await ajaxUtil.synAjax("api/auth/login", "POST", array);
         
    if(responseUtils.validateResponse(result))
    {
       let data = responseUtils.getSuccessResponse(result);
       let farmerAppUsers = responseSetters.setFarmerAppUsersResponse(data[FarmerAppUsersKeys.FARMER_APP_USER_TABLE_NAME]);
       
       // Saving to local storage.
       localStorage.setItem(LocalStorageConstants.USER_ID, farmerAppUsers._farmerAppUserId);
       localStorage.setItem(LocalStorageConstants.TOKEN, farmerAppUsers._sessionKey);
       
       // Changing flag to authenticated.
       setIsAuthenticated(true);

       props.showAlert(result['message'], AlertConstants.SUCCESS);
    }
    else
    {
        let data = responseUtils.getFailureResponseCode(result);
        props.showAlert(errorUtils.getErrorMessage(data), AlertConstants.FAILED);
    }
    props.dismissProgress();

  }
  if(success)
  {
    // return <Redirect to="/login/otp" push/>
    componentDetails = <OtpVerification otpPassword={otpPassword} setOtpPassword={setOtpPassword} verifyOtp={verifyOtp} timeout={timeout}/>
  }
  else
  {
    if(isAuthenticated)
    {
      
    }
    else
    {
     componentDetails = <MobileVerification phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} login={login}/>
    }
  }

  return (
    <div className="app_container">
      <div className="app_container_header"></div>
      <div className="app_container_content">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-4" />
          <div className="col-sm-8 col-md-8 col-lg-4">
            <div className="app_row_flex app_row_flex_justify_content_center app_margin_default_top">
              <ImageViewUtils
                isLocal="true"
                imageName="applogo.png"
                className="app_small_icon app_row_flex_item"
              />
              <TextViewUtils
                className="app_row_flex_item app_row_flex_item app_margin_default_left app_large_text"
                text={strings.app_name}
              />
            </div>

            <div className="row app_margin_default_top">
              <div className="col-sm-12 col-md-12 col-lg-2" />
              <div className="col-sm-12 col-md-12 col-lg-8">


                {componentDetails}


              </div>
              <div className="col-sm-12 col-md-12 col-lg-2" />
            </div>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-4" />
        </div>

      </div>
      <div className="app_container_footer">
        <ImageViewUtils
          className="app_cover_image"
          isLocal="true"
          imageName="background14.png"
        />
      </div>
    </div>
  );
}

import React from "react";
import { TextViewUtils } from "../../../utils/ui-comp/text-view-utils";
import { ImageViewUtils } from "../../../utils/ui-comp/img-view-utils";
import { TextBoxUtils } from "../../../utils/ui-comp/textbox-view-utils";
import { ButtonUtils } from "../../../utils/ui-comp/buttom-view-utils";
import { strings } from "../../../locale/locale-properties";

export function MobileVerification(props) {
  return (
    <form onSubmit={props.login}>
      <div>
        <TextViewUtils
          className="app_small_text"
          text={strings.login_page_login_title_label}
        />
      </div>
      <div className="app_items_top_margin">
        <TextBoxUtils
          placeholder={strings.login_page_login_phone_number_placeholder}
          inputType="number"
          value={props.phoneNumber}
          onChangeValue={props.setPhoneNumber}
        />
      </div>
      <div className="app_items_top_margin">
        <ButtonUtils
          inputType="submit"
          title={strings.login_page_login_button_title}
        />
      </div>
    </form>
  );
}

import React, { useState } from "react";
import { ScheduleModel } from "../../../../bean/schedule/ScheduleModel";

import { strings } from "../../../../locale/locale-properties";
import { NoDataFound } from "../../../../utils/common-comp/no-data-found";
import { TextRoundButton } from "../../../../utils/common-comp/text-round-button";
import commonUtils, { CommonUtils } from "../../../../utils/common-utils";
import scheduleNetworkUtils from "../../../../utils/network/schedule/schedule-network-utils";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { ButtonUtils } from "../../../../utils/ui-comp/buttom-view-utils";
import CheckBoxUtils from "../../../../utils/ui-comp/check-box-utils";
import DatePickerUtils from "../../../../utils/ui-comp/date-picker-utils";
import { ImageViewUtils } from "../../../../utils/ui-comp/img-view-utils";
import RadioButtonUtils from "../../../../utils/ui-comp/radio-button-utils";
import SelectBoxUtils from "../../../../utils/ui-comp/selectbox-utils";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
import { TextBoxUtils } from "../../../../utils/ui-comp/textbox-view-utils";
import { getAlertDetails } from "../../../../utils/ui-utils/alert-utils";
export default function ScheduleView(props) {
    let alert = props.alert;
    // let alertStatus = getAlertDetails(alert._alertLevel);
    let [loading, setLoading] = useState(false);
    let [scheduleWindow, setScheduleWindow] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    

    let [scheduleName, setScheduleName] = useState();
    let [startTimeHr, setStartTimeHr] = useState('01');
    let [startTimeMin, setStartTimeMin] = useState('00');
    let [startTimeAmPm, setStartTimeAmPm] = useState('AM');
    let [endTimeHr, setEndTimeHr] = useState('01');
    let [endTimeMin, setEndTimeMin] = useState('00');
    let [endTimeAmPm, setEndTimeAmPm] = useState('AM');
    let [repeatMode, setRepeatMode] = useState(false);
    let [repeatModeVal, setRepeatModeVal] = useState(24);
    let component;
    let hours = [];
    for (let position = 1; position <= 12; position++) 
    {
        let arr = {};
        let val = position;
        if (position < 10) {
            val = '0' + val;
        }
        arr['key'] = val+'';
        arr['data'] = val+'';
        hours.push(arr);
    }
    let minutes = [];
    for (let position = 0; position <= 59; position++) {
        let arr = {};
        let val = position;
        if (position < 10) {
            val = '0'+val;
        }
        arr['key'] = val+'';
        arr['data'] =  val+'';
        minutes.push(arr);
    }
    let timeAmPm = [];
    let ampmArr = {};
    ampmArr['key'] = 'AM';
    ampmArr['data'] = 'AM';
    timeAmPm.push(ampmArr);

    ampmArr = {};
    ampmArr['key'] = 'PM';
    ampmArr['data'] = 'PM';
    timeAmPm.push(ampmArr);

    function openScheduleWindow(evt) {
        evt.preventDefault();
        setScheduleWindow(true);

    }
    function onBackPressed(evt) {
        setScheduleWindow(false);
    }

    function textBoxCallback(key, val) 
    {
        if(key === 'scheduleName')
        {
            setScheduleName(val);
        }
        else if(key == 'date')
        {
            setStartDate(val);
        }
        else if(key === 'startTimeHr')
        {
            setStartTimeHr(val);
        }
        else if(key === 'startTimeMin')
        {
            setStartTimeMin(val);
        }
        else if(key === 'startTimeAmPm')
        {
            setStartTimeAmPm(val);
        }
        else if(key === 'endTimeHr')
        {
            setEndTimeHr(val);
        }
        else if(key === 'endTimeMin')
        {
            setEndTimeMin(val);
        }
        else if(key === 'endTimeAmPm')
        {
            setEndTimeAmPm(val);
        }
        else if(key === 'everyDay' || key == 'everyWeek')
        {
            setRepeatModeVal(val);
        }
        console.log(val)
    }

    async function saveScheduleCallback(evt)
    {
        evt.preventDefault();

        let startHr = commonUtils.get24HrsTime(startTimeHr, startTimeAmPm);
        let endHr = commonUtils.get24HrsTime(endTimeHr, endTimeAmPm);

        let startTime = startDate + " " + startHr + ":" + startTimeMin;
        let endTime = startDate + " " + endHr + ":" + endTimeMin;
        
        let details = new ScheduleModel();
        details._scheduleName = scheduleName;
        details._startTime = startTime;
        details._stopTime = endTime;
        if(repeatMode ==true)
        {
            details._repeatMode = 'enabled';
            details._repeatTime = repeatModeVal;
        }
        let requestData = responseSetters.toJSONScheduleDetails(details);
        let result = await scheduleNetworkUtils.createOrEditSchedule(props.motorId, requestData);
        
        
    }

    function checkboxOnChanged(checked) {
        setRepeatMode(checked);
    }

    let repeatModeComp;
    if (repeatMode) {
        repeatModeComp =
            <div className="app_items_top_margin app_column_flex app_margin_default_left">
                <div className="app_row_flex">
                    <div className="app_column_flex">
                        <div className="app_margin_top_10">
                            <RadioButtonUtils onChangeValue={textBoxCallback} callbackWithKey='everyDay' className="app_small_text" name='repeatMode' originalValue='24' value={strings.schedule_repeat_mode_every_day} checked='true' />
                        </div>
                        <div className="app_margin_top_10">
                            <RadioButtonUtils onChangeValue={textBoxCallback} callbackWithKey='everyWeek' className="app_small_text" name='repeatMode' originalValue='168' value={strings.schedule_repeat_mode_every_week} />
                        </div>
                    </div>

                </div>
            </div>;
    }

    if (loading) {
        component = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
    }
    else {
        if (!scheduleWindow) {
            component = <TextRoundButton text={strings.schedule_create_button} callback={openScheduleWindow} />
        }
        else {
            component =
                <div className="app_column_flex">
                    <div className="app_row_flex">
                        <ImageViewUtils onClick={onBackPressed} className="app_mid_small_icon app_align_self_center" imageName="backbutton.png" />
                        <TextViewUtils
                            className="app_small_text app_text_bold app_align_self_center app_margin_default_left"
                            text={strings.schedule_enter_schedule_details} />
                    </div>
                    <form onSubmit={saveScheduleCallback}>
                        <div className="app_items_top_margin">
                            <TextBoxUtils
                                placeholder={strings.schedule_name}
                                inputType="text"
                                value={props.phoneNumber}
                                onChangeValue={textBoxCallback}
                                callbackWithKey='scheduleName'
                            />
                        </div>
                        <div className="app_items_top_margin app_column_flex">
                            <TextViewUtils
                                className="app_small_text app_text_bold"
                                text={strings.schedule_date} />
                            <div className="app_items_top_margin">
                                <DatePickerUtils onChangeValue={textBoxCallback}
                                    callbackWithKey='date'
                                />
                            </div>
                        </div>
                        <div className="app_items_top_margin app_column_flex">
                            <TextViewUtils
                                className="app_small_text app_text_bold"
                                text={strings.start_time} />
                            <div className="app_row_flex app_items_top_margin">
                                <div className="">
                                    <SelectBoxUtils callbackWithKey='startTimeHr' onChangeValue={textBoxCallback} options={hours} />
                                </div>
                                <div className="app_margin_default_left">
                                    <SelectBoxUtils callbackWithKey='startTimeMin' onChangeValue={textBoxCallback} options={minutes} />
                                </div>
                                <div className="app_margin_default_left">
                                    <SelectBoxUtils callbackWithKey='startTimeAmPm' onChangeValue={textBoxCallback} options={timeAmPm} />
                                </div>
                            </div>

                        </div>
                        <div className="app_items_top_margin app_column_flex">
                            <TextViewUtils
                                className="app_small_text app_text_bold"
                                text={strings.end_time} />
                            <div className="app_row_flex app_items_top_margin">
                                <div className="">
                                    <SelectBoxUtils callbackWithKey='endTimeHr' onChangeValue={textBoxCallback} options={hours} />
                                </div>
                                <div className="app_margin_default_left">
                                    <SelectBoxUtils callbackWithKey='endTimeMin' onChangeValue={textBoxCallback} options={minutes} />
                                </div>
                                <div className="app_margin_default_left">
                                    <SelectBoxUtils callbackWithKey='endTimeAmPm' onChangeValue={textBoxCallback} options={timeAmPm} />
                                </div>
                            </div>

                        </div>
                        <div className="app_items_top_margin app_column_flex">
                            <div className="app_row_flex app_items_top_margin">
                                <CheckBoxUtils className="app_align_self_center" onChange={checkboxOnChanged} />
                                <TextViewUtils
                                    className="app_small_text  app_align_self_center app_margin_default_left"
                                    text={strings.schedule_repeat_mode} />
                            </div>
                        </div>
                        {repeatModeComp}

                        <div className="app_items_top_margin">
                            <ButtonUtils
                                inputType="submit"
                                title={strings.schedule_save}
                            />
                        </div>
                    </form>
                </div>
        }
    }

    return (<div style={{ width: "100%", height: "100%", padding: 10 }}>
        <div className="row reverse_row app_division" style={{ height: '100%' }}>
            <div
                className="col-lg-12 col-md-12 col-sm-12"
                style={{ position: "relative" }}>
                {component}
            </div>
        </div>
    </div>);
}
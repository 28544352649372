import ajaxUtil from "../../../../utils/network/ajax-util";
import React, { useEffect, useState } from "react";

import WidgetComp from "../../../../utils/ui-comp/widget-comp";
import { FilterKeys } from "../../../../constants/filter-keys";
import responseUtils from "../../../../utils/network/response-util";
import { LocalStorageConstants } from "../../../../constants/local-storage-constants";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { strings } from "../../../../locale/locale-properties";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
export default function MotorRunTime(props)
{

    let [isLoading, setLoading] = useState(true);
    let [widget, setWidget] = useState();

    let body = {};

    let motorId = props.motor.motorId;
    let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    body[FilterKeys.WIDGET_RUNNING_TIME_BY_MANUAL] = 'true';
    body[FilterKeys.WIDGET_RUNNING_TIME_BY_MOBILE_APP] = 'true';
    body[FilterKeys.WIDGET_RUNNING_TIME_BY_SCHEDULE] = 'true';


    function getWidgetDetails(result)
    {
        let data = responseUtils.getSuccessResponse(result);
        console.log(data);
        let widgetDetails = responseSetters.setWidgetDetails(result['data']);
        // let motors = motorList;

        console.log(widgetDetails);
       setWidget(widgetDetails);
       setLoading(false) ;
    }

    let componetDetails;

    useEffect(()=>{
        ajaxUtil.asyncAjax(
            "api/user/"+userId+"/widget/"+motorId,
            "PUT",
            body,
            getWidgetDetails
          );
      },[]);
    
      if(isLoading)
      {
         componetDetails = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
      }
      else
      {
        // console.log(motorList)
        componetDetails = 
            <div className='row '>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <TextViewUtils className='app_small_text app_text_bold' text={strings.motor_total_runtime_title}/>
              </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-4 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_phone_iphone_white_24dp.png' backgroundColor='red-background-color' title={strings.motor_run_by_app} value={widget._widgetRunningTimeByMobileApp} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-4 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_schedule_white_24dp.png' backgroundColor='yellow-background-color' title={strings.motor_run_by_schedule} value={widget._widgetRunningTimeBySchedule} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-4 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_engineering_white_24dp.png' backgroundColor='green-background-color' title={strings.motor_run_by_manual} value={widget._widgetRunningTimeByManual} type='align-vertical-1'/>
                </div>
            </div>;
      }
    
        return (
          <div style={{ width: "100%", height: "100%", position: "relative", padding: 10 }}>
            {componetDetails}
          </div>
        );
}
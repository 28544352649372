import React, { useState, useEffect } from "react";
import { TextViewUtils } from "../../../utils/ui-comp/text-view-utils";
import { ImageViewUtils } from "../../../utils/ui-comp/img-view-utils";
import { TextBoxUtils } from "../../../utils/ui-comp/textbox-view-utils";
import { ButtonUtils } from "../../../utils/ui-comp/buttom-view-utils";
import { strings } from "../../../locale/locale-properties";

export function OtpVerification(props) {


  const [minValState, minValSetState] = useState(2);
  const [secValState, secValSetState] = useState(0);

  // Timer for otp verification
  useEffect(() => {
    let id = setInterval(() => {
      if (secValState == 0) {
        if (minValState == 0) {
          // Closing current page
          props.timeout();
        } else {
          minValSetState(minValState - 1);
          secValSetState(59);
        }
      } else {
        secValSetState(secValState - 1);
      }
    }, 1000);
    return () => clearInterval(id);
  });

  return (
    <form onSubmit={props.verifyOtp}>
      <div>
        <TextViewUtils
          className="app_small_text"
          text={strings.login_page_otp_enter_otp_label}
        />
      </div>
      <div className="app_items_top_margin">
        <TextBoxUtils
          placeholder={strings.login_page_otp_password_placeholder}
          inputType="password"
          value={props.otpPassword}
          onChangeValue={props.setOtpPassword}
        />
      </div>
      <div className="app_items_top_margin">
      <TextViewUtils
          className="app_small_text"
          text={strings.login_page_otp_remaining_time}/>
          <TextViewUtils
          className="app_small_text app_items_left_margin"
          text={minValState + ' : ' + secValState} />
      </div>
      <div className="app_items_top_margin">
        <ButtonUtils
          inputType="submit"
          title={strings.login_page_otp_button_title}
        />
      </div>
    </form>
  );
}



import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextViewUtils } from "./text-view-utils";
export function NavBarComp(props)
{

  function onClick(position)
  {
    //props.setCurrentPage(position);
  }

    let navList = [];
    for(let nav in props.navList)
    {
        let text = props.navList[nav].text;
        let linkTo = props.navList[nav].linkTo;

        let style = 'nav-item';
        if(nav == props.active)
        {
            style += ' active';
        }

         navList.push
         (<Link key={nav} className={style} to={linkTo}>
        <TextViewUtils className="app_small_text" text={text}/>
      </Link>);
    }
    let navData = 
    <div className="navbar">
          {navList}
        </div>;

        return navData;
}
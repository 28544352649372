import React from "react";
import "../../components/login/Login.css";
export function ImageViewUtils(props)
{

    let style = props.style;
    let isLocalImage = props.isLocal;
    let path = props.isSvg=="true"? props.imageName : require('../../assets/img/'+props.imageName);
    if(props.onClick!=undefined)
    {
        return (<img onClick={props.onClick} className={props.className} src={path}/>);
    }

    return (<img className={props.className} src={path}/>);
}
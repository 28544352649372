import React, { useState } from "react";
import { LocalStorageConstants } from "../../constants/local-storage-constants";
import "./Home.css";
import { TextViewUtils } from "../../utils/ui-comp/text-view-utils";
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  useHistory,
  Redirect,
  HashRouter,
} from "react-router-dom";
import { NavBarComp } from "../../utils/ui-comp/nav-bar-comp";
import { NavBarConstants } from "../../constants/app-nav-bar-constants";
import { Login } from "../login/Login";
import { Motor } from "./motor/Motor";
import { createBrowserHistory } from "history";
import { Dashboard } from "./dashboard/Dashboard";
import Alert from "./alerts/Alert";
import { Schedule } from "./schedule/Schedule";
import { ImageViewUtils } from "../../utils/ui-comp/img-view-utils";
import { strings } from "../../locale/locale-properties";
export function Home(props) {
  const [loggedInStatus, setLoggedInStatus] = useState(true);

  if (localStorage.getItem(LocalStorageConstants.TOKEN) == null) {
    return <Redirect to="/" />;
  }

  if (!loggedInStatus) {
    localStorage.clear();
    return <Redirect to="/" />
  }

  return (
    <div className="app_container">
     
      <div className="app_container_header">
    
       
        <div className="app_row_flex row" style={{ height: '100%' }}>
          <div className="col-lg-1 col-md-1 col-sm-12"></div>
          <div className="col-lg-11 col-md-11 col-sm-12 app_row_flex">
            <div className="app_align_self_center app_margin_default_left" >
              <ImageViewUtils imageName='applogo.png' className='app_mid_small_icon' />
            </div>
            <div className="app_align_self_center app_margin_default_left" >
              <TextViewUtils
                className="app_medium_text app_text_bold"
                text={strings.app_name} />
            </div>
          </div>
        </div>
        
      </div>
      <div className="app_container_content">
        <HashRouter>
          <Switch>
            <Route exact path="/home">
              <Dashboard />
            </Route>
            <Route path="/home/motor">
              <Motor setLoggedInStatus={setLoggedInStatus} />
            </Route>
            <Route path="/home/alerts">
              <Alert />
            </Route>
            <Route path="/home/schedule">
              <Schedule />
            </Route>
          </Switch>
        </HashRouter>
      </div>
      <div className="app_container_footer">
        <ImageViewUtils
          className="app_cover_image"
          isLocal="true"
          imageName="background14.png"
        />
      </div>
    </div>
  );
}

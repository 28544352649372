import { LocalStorageConstants } from "../../../constants/local-storage-constants";
import responseSetters from "../../response-setters/response-setters";
import ajaxUtil from "../ajax-util";
import responseUtils from "../response-util";

class MotorNetworkUtil {
    userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    constructor() {
        if (!MotorNetworkUtil.instance) {
            MotorNetworkUtil.instance = this;
        }
        return MotorNetworkUtil.instance;
    }

    getAllMotors(callbackFunction)
    {
        ajaxUtil.asyncAjax("api/user/" + this.userId + "/motor", "GET", "", callbackFunction);
    }

}

const motorNetworkUtil = new MotorNetworkUtil();
Object.freeze(motorNetworkUtil);
export default motorNetworkUtil;
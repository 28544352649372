export function getNavId(list, path)
{
    for(let l in list)
    {
        if(list[l].linkTo === path)
        {
            return l;
        }
    }
    return 0;
}
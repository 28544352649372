import React, { useEffect, useState } from "react";
import { ImageViewUtils } from "./img-view-utils";
import { TextViewUtils } from "./text-view-utils";
export default function WidgetComp(props) {

    let imageBorder = 'app_row_flex_item app_column_flex app_row_flex_justify_content_center '+props.backgroundColor;

    return (
        <div className='card'>
            <div class="card-container app_column_flex">
                <div className={imageBorder} style={{borderRadius: 25, width: 35, height: 35, padding: 10}}>
                    <ImageViewUtils
                        isLocal="true"
                        imageName={props.icon}
                        className="app_smaller_icon app_row_flex_item"
                         />
                </div>
                
                <div className='app_row_flex_item app_margin_top_10'>
                    <TextViewUtils className='app_small_text app_text_bold' text={props.title} />
                </div>
                <div className='app_row_flex_item'>
                    <TextViewUtils className='app_smaller_text app_text_bold' text={props.value} />
                </div>
            </div>
        </div>
    );
}
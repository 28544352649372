import ajaxUtil from "../../../../utils/network/ajax-util";
import React, { useEffect, useState } from "react";

import WidgetComp from "../../../../utils/ui-comp/widget-comp";
import { FilterKeys } from "../../../../constants/filter-keys";
import responseUtils from "../../../../utils/network/response-util";
import { LocalStorageConstants } from "../../../../constants/local-storage-constants";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { strings } from "../../../../locale/locale-properties";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
export default function MotorAllNotification(props)
{
    let [isLoading, setLoading] = useState(true);
    let [widget, setWidget] = useState();
    const [motorId, setMotorId] = useState();

    let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    let body = {};
    body[FilterKeys.WIDGET_TOTAL_RUNTIME] = 'true';
    body[FilterKeys.WIDGET_AVERAGE_TEMPERATURE] = 'true';
    body[FilterKeys.WIDGET_AVERAGE_VIBRATION] = 'true';
    body[FilterKeys.WIDGET_TOTAL_PHASE_ALERTS] = 'true';
    body[FilterKeys.WIDGET_TOTAL_CRITICAL_ALERTS] = 'true';

    let mid;
    if(props.motor != undefined)
    {
        mid = props.motor._motorId
    }
    else
    {
        mid = props.motorId;
        console.log(motorId);
    }
    if(motorId != mid)
    {
        getNotifications();
    }

    function getNotifications()
    {
        ajaxUtil.asyncAjax(
            "api/user/"+userId+"/widget/"+mid,
            "PUT",
            body,
            getWidgetDetails
          );
    }

    function getWidgetDetails(result)
    {
        let data = responseUtils.getSuccessResponse(result);
        console.log(data);
        let widgetDetails = responseSetters.setWidgetDetails(result['data']);
        // let motors = motorList;

        console.log(widgetDetails);
        setMotorId(mid);
        setWidget(widgetDetails);
        setLoading(false) ;
    }

    let componetDetails;

    useEffect(()=>{
        
      },[]);
    
      if(isLoading)
      {
         componetDetails = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
      }
      else
      {
        // console.log(motorList)
        componetDetails = 
            <div className='row '>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                <TextViewUtils className='app_small_text app_text_bold' text={strings.motor_all_notifications_title}/>
              </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_run_circle_white_24dp.png' backgroundColor='red-background-color' title={strings.motor_total_run_time} value={widget._widgetTotalRuntime} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_brightness_5_white_24dp.png' backgroundColor='blue-background-color' title={strings.motor_average_temperature} value={widget._widgetAverageTemperature} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_vibration_white_24dp.png' backgroundColor='green-background-color' title={strings.motor_average_vibration} value={widget._widgetAverageVibration} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_notifications_white_24dp.png' backgroundColor='yellow-background-color' title={strings.motor_total_phase_alerts} value={widget._widgetTotalPhaseAlerts} type='align-vertical-1'/>
                </div>
                {/* <div className='col-lg-1 col-md-1 col-sm-12'></div> */}
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <WidgetComp icon='baseline_notifications_active_white_24dp.png' backgroundColor='orange-background-color' title={strings.motor_total_critical_alerts} value={widget._widgetTotalCriticalAlerts} type='align-vertical-1'/>
                </div>
            </div>;
      }
    
        return (
          <div style={{ width: "100%", height: "100%", position: "relative", padding: 10 }}>
            {componetDetails}
          </div>
        );
}
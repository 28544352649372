import React, { useEffect, useState } from "react";
import { strings } from "../../../../locale/locale-properties";
import { ButtonUtils } from "../../../../utils/ui-comp/buttom-view-utils";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
export function MotorStatusChange(props)
{
    let motorAction = 'OFF';
    let motorStatusText = strings.motor_status_button_off;
    console.log(props.motor)
    if(props.motor._motorStatus == 'OFF')
    {
        console.log(props.motor._motorStatus);
        motorStatusText = strings.motor_status_button_on;
        motorAction = 'ON';
    }

    let componentDetails = 
    <div className='app_column_flex app_justify_content_center'>
        <TextViewUtils text={strings.click_button_to_change_motor_status} className='app_align_self_center app_smaller_text app_text_bold'/>

        <div className="app_items_top_margin app_align_self_center">
        <form onSubmit={(e)=>props.onMotorStatusChange(e, motorAction)}>
            <ButtonUtils
                inputType="submit"
                title={motorStatusText}
            />
        </form>
        
      </div>
    </div>
    return(
        <div>
            {componentDetails}
        </div>
    );
}
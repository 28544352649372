export class MotorKeys
{
    static TABLE = "motors";

    static MOTOR_ID = "mid";
    static MOTOR_NAME = "motor_name";
    static MOTOR_TYPE = "motor_type";
    static MOTOR_BRAND = "motor_brand";
    static MOTOR_SERIALNO = "serial_no";
    static MOTOR_DATE_PURCHASED = "date_purchased";
    static MOTOR_WARRANTY = "warranty";
    static MOTOR_POWER_CONSUMPTION = "power_consumption";
    static MOTOR_REMARKS = "motor_remarks";
    static MOTOR_AREA_COVERS = "area_covers";
    static MOTOR_LOCATION_LANG = "motor_long";
    static MOTOR_LOCATION_LAT = "motor_lat";

    static DATE_ADDED = "date_added";
    static LAST_MODIFIED = "last_modified";
    static MOTOR_STATUS = "motor_status";
    static MOTOR_TOTALRUNTIME = "motor_totalruntime";
    static MOTOR_STATUS_UPDATED = "motor_status_updated";
    static MOTOR_STATUS_OPERATOR = "motor_status_operator";
    static MOTOR_STATUS_OPERATEDBY = "motor_status_operatedby";
    static MOTOR_UPDATE_TABLE = "motor_update_table";
    static MOTOR_UPDATE_PAYLOAD = "motor_update_payload";

    static ACTIVE = "active";
    static FARMER_LAND_CROPFIELDS_CROPFIELD_ID = "farmer_land_cropfields_cropfield_id";

}
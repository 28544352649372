import React, { useEffect, useState } from "react";
import { FilterConstants } from "../../../../constants/filter-constants";
import { LocalStorageConstants } from "../../../../constants/local-storage-constants";
import ajaxUtil from "../../../../utils/network/ajax-util";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import responseUtils from "../../../../utils/network/response-util";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { ListViewComp } from "../../../../utils/ui-comp/list-view-comp";
import { NoDataFound } from "../../../../utils/common-comp/no-data-found";


export default function ScheduleList(props)
{

    function genFilter(offset, limit, filter)
    {
        return  FilterConstants.OFFSET+'='+offset+'&'+FilterConstants.LIMIT+'='+limit+'&'+FilterConstants.FILTER+'='+filter;
    }

    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [motorId, setMotorId] = useState();

    let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    if(motorId !== props.motorId && props.motorId > 0)
    {
       getSchedules(0, 1000000, '1=1');
    }

    function callbackFunction(result) 
    {
        console.log(result);
        if(responseUtils.validateResponse(result))
        {
            let itemList = [];
            
            let data = responseUtils.getSuccessResponse(result);

            for (let pos = 0; pos < data.length; pos++) 
            {
                let schedule = responseSetters.setScheduleDetails(data[pos]);
                itemList.push(
                    <ListViewComp
                      id={schedule._scheduleId}
                      key={pos}
                      title={schedule._scheduleName}
                      info={schedule._startTime}
                      icon="motor.png"
                      callback={onClickList}
                    />
                  );
            }
            setMotorId(props.motorId);
            setItems(itemList);
            setLoading(false);
        }
    }
    function onClickList(alertId)
    {
        console.log(alertId);
        props.callback(alertId);
    }

    function getSchedules(offset, limit, filter)
    {
        ajaxUtil.asyncAjax(
            "api/user/" + userId + "/motor/"+props.motorId+"/schedule?"+genFilter(offset, limit, filter),
            "GET",
            "",
            callbackFunction
          );
    }

    useEffect(()=>
     {
       setLoading(true);
      },[]);

      let componetDetails;
      if(isLoading)
      {
         componetDetails = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
      }
      else
      {
        if(items.length > 0)
        {
          componetDetails = <div>{items}</div>;
        }
        else
        {
          componetDetails = <NoDataFound/>;
        }
        // console.log(alertlist)
        
      }
    
        return (
          <div style={{ width: "100%", height: "100%", position: "relative", padding: 10, overflowY: 'scroll' }}>
            {componetDetails}
          </div>
        );
}
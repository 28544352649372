export class ErrorConstants
{
    static INVALID_USER = 101;
    static INVALID_OTP = 102;
    static INVALID_TOKEN = 103;

    static SCHEDULE_ALREADY_EXISTS = 105;
    static SHCEDULE_INVALID_DETAILS = 106;

    static NETWORK_ERROR = 200;
    static UNKNOWN_ERROR = 201;

    
}
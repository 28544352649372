import { AlertModel } from "../../bean/alert/AlertModel";
import { FarmerAppUsers } from "../../bean/farmer/FarmerAppUsers";
import { MotorModel } from "../../bean/motor/MotorModel";
import { ScheduleModel } from "../../bean/schedule/ScheduleModel";
import { WidgetModel } from "../../bean/widget/WidgetModel";
import { AlertKeys } from "../../constants/alert-keys/alert-keys";

import { FarmerAppUsersKeys } from "../../constants/farmer-keys/farmer-app-users-keys";
import { FilterKeys } from "../../constants/filter-keys";
import { MotorKeys } from "../../constants/motor-keys/motor-keys";
import { ScheduleKeys } from "../../constants/schedule-keys/ScheduleKeys";

export class ResponseSetters {
    constructor() {
        if (!ResponseSetters.instance) {
            ResponseSetters.instance = this;
        }
        return ResponseSetters.instance;
    }

    setFarmerAppUsersResponse(result) {
        let farmerAppUser = new FarmerAppUsers();

        farmerAppUser._farmerAppUserId = result[FarmerAppUsersKeys.FARMER_USER_ID];
        farmerAppUser._farmerEmail = result[FarmerAppUsersKeys.FARMER_EMAIL];
        farmerAppUser._farmerPhone = result[FarmerAppUsersKeys.FARMER_PHONE];
        farmerAppUser._username = result[FarmerAppUsersKeys.FARMER_USERNAME];
        farmerAppUser._farmerIMEI = result[FarmerAppUsersKeys.FARMER_IMEI];
        farmerAppUser._password = result[FarmerAppUsersKeys.FARMER_PASSWORD];
        farmerAppUser._privateKey = result[FarmerAppUsersKeys.FARMER_PRIVATE_KEY_PATH];
        farmerAppUser._dateAdded = result[FarmerAppUsersKeys.DATE_ADDED];
        farmerAppUser._lastModified = result[FarmerAppUsersKeys.LAST_MODIFIED];
        farmerAppUser._sessionActive = result[FarmerAppUsersKeys.SESSION_ACTIVE];
        farmerAppUser._sessionKey = result[FarmerAppUsersKeys.SESSION_KEY];
        farmerAppUser._active = result[FarmerAppUsersKeys.ACTIVE];
        farmerAppUser._farmersFarmerId = result[FarmerAppUsersKeys.FARMERS_FARMER_ID];
        return farmerAppUser;
    }

    setMotorDetails(result) {
        if (result['motors']) {
            result = result['motors'];
        }
        let motor = new MotorModel();

        motor._motorId = result[MotorKeys.MOTOR_ID];
        motor._motorName = result[MotorKeys.MOTOR_NAME];
        motor._motorType = result[MotorKeys.MOTOR_TYPE];
        motor._motorBrand = result[MotorKeys.MOTOR_BRAND];
        motor._motorSerialNumber = result[MotorKeys.MOTOR_SERIALNO];
        motor._motorDatePurchased = result[MotorKeys.MOTOR_DATE_PURCHASED];
        motor._motorWarranty = result[MotorKeys.MOTOR_WARRANTY];
        motor._motorPowerConsumption = result[MotorKeys._motorPowerConsumption];
        motor._motorNote = result[MotorKeys.MOTOR_REMARKS];
        motor._motorAreaCovers = result[MotorKeys.MOTOR_AREA_COVERS];
        motor._motorLocationLang = result[MotorKeys.MOTOR_LOCATION_LANG];
        motor._motorLocationLat = result[MotorKeys.MOTOR_LOCATION_LAT];
        motor._dateAdded = result[MotorKeys.DATE_ADDED];
        motor._lastModified = result[MotorKeys.LAST_MODIFIED];
        motor._motorStatus = result[MotorKeys.MOTOR_STATUS];
        motor._motorTotalRunTime = result[MotorKeys.MOTOR_TOTALRUNTIME];
        motor._motorStatusUpdated = result[MotorKeys.MOTOR_STATUS_UPDATED];
        motor._motorStatusOperator = result[MotorKeys.MOTOR_STATUS_OPERATOR];
        motor._motorStatusOperatedBy = result[MotorKeys.MOTOR_STATUS_OPERATEDBY];
        motor._motorUpdateTable = result[MotorKeys.MOTOR_UPDATE_TABLE];
        motor._motorUpdatePayload = result[MotorKeys.MOTOR_UPDATE_PAYLOAD];
        motor._active = result[MotorKeys.ACTIVE];
        motor._farmerLandCropFieldsId = result[MotorKeys.FARMER_LAND_CROPFIELDS_CROPFIELD_ID];

        return motor;

    }

    setWidgetDetails(result) {
        let widgetModel = new WidgetModel();
        widgetModel._date = result[FilterKeys.DATE];
        let _widgetRunningTimeByMobileApp = result[FilterKeys.WIDGET_RUNNING_TIME_BY_MOBILE_APP];
        let _widgetRunningTimeBySchedule = result[FilterKeys.WIDGET_RUNNING_TIME_BY_SCHEDULE];
        let _widgetRunningTimeByManual = result[FilterKeys.WIDGET_RUNNING_TIME_BY_MANUAL];
        let _widgetAverageTemperature = result[FilterKeys.WIDGET_AVERAGE_TEMPERATURE];
        let _widgetAverageVibration = result[FilterKeys.WIDGET_AVERAGE_VIBRATION];
        let _widgetTotalRuntime = result[FilterKeys.WIDGET_TOTAL_RUNTIME];
        let _widgetTotalPhaseAlerts = result[FilterKeys.WIDGET_TOTAL_PHASE_ALERTS];
        let _widgetTotalCriticalAlerts = result[FilterKeys.WIDGET_TOTAL_CRITICAL_ALERTS];

        widgetModel._widgetRunningTimeByMobileApp = _widgetRunningTimeByMobileApp === null ? '0 Hr 0 Min' : _widgetRunningTimeByMobileApp;
        widgetModel._widgetRunningTimeBySchedule = _widgetRunningTimeBySchedule === null ? '0 Hr 0 Min' : _widgetRunningTimeBySchedule;
        widgetModel._widgetRunningTimeByManual = _widgetRunningTimeByManual === null ? '0 Hr 0 Min' : _widgetRunningTimeByManual;
        widgetModel._widgetAverageTemperature = _widgetAverageTemperature === null ? 0 : _widgetAverageTemperature;
        widgetModel._widgetAverageVibration = _widgetAverageVibration === null ? 0 : _widgetAverageVibration;
        widgetModel._widgetTotalRuntime = _widgetTotalRuntime === null ? '0 Hr 0 Min' : _widgetTotalRuntime;
        widgetModel._widgetTotalPhaseAlerts = _widgetTotalPhaseAlerts === null ? 0 : _widgetTotalPhaseAlerts;
        widgetModel._widgetTotalCriticalAlerts = _widgetTotalCriticalAlerts === null ? 0 : _widgetTotalCriticalAlerts;
        return widgetModel;
    }

    setAlertDetails(result) {
        let alertModel = new AlertModel();
        alertModel._alertId = result[AlertKeys.ALERT_ID];
        alertModel._alertName = result[AlertKeys.ALERT_NAME];
        alertModel._alertTime = result[AlertKeys.ALERT_TIME];
        alertModel._alertValue = result[AlertKeys.ALERT_VALUE];
        alertModel._alertLevel = result[AlertKeys.ALERT_LEVEL];
        alertModel._alertReferenceId = result[AlertKeys.ALERT_REFERENCE_ID];
        alertModel._alertReferenceTable = result[AlertKeys.ALERT_REFERENCE_TABLE];
        alertModel._alertReferenceTableField = result[AlertKeys.ALERT_REFERENCE_TABLE_FIELD];
        alertModel._dateAdded = result[AlertKeys.DATE_ADDED];
        alertModel._displayMsg = result[AlertKeys.DISPLAY_MSG];
        alertModel._farmerUserId = result[AlertKeys.FARMER_USER_ID];
        alertModel._gid = result[AlertKeys.GID];
        alertModel._mid = result[AlertKeys.MID];
        alertModel._viewStatus = result[AlertKeys.VIEW_STATUS];
        alertModel._motorName = result[MotorKeys.MOTOR_NAME];

        return alertModel;
    }

    setScheduleDetails(result) {
        let scheduleModel = new ScheduleModel();
        scheduleModel._scheduleId = result[ScheduleKeys.MOTOR_SCHEDULE_ID];
        scheduleModel._scheduleName = result[ScheduleKeys.SCHEDULE_NAME];
        scheduleModel._scheduleDescription = result[ScheduleKeys.SCHEDULE_DESCRIPTION];
        scheduleModel._startTime = result[ScheduleKeys.START_TIME];
        scheduleModel._stopTime = result[ScheduleKeys.STOP_TIME];
        scheduleModel._repeatMode = result[ScheduleKeys.REPEAT_MODE];
        scheduleModel._repeatTime = result[ScheduleKeys.REPEAT_TIME];
        scheduleModel._days = result[ScheduleKeys.DAYS];
        scheduleModel._dateAdded = result[ScheduleKeys.DATE_ADDED];
        scheduleModel._lastModified = result[ScheduleKeys.LAST_MODIFIED];
        scheduleModel._mid = result[ScheduleKeys.MID];
        scheduleModel._gid = result[ScheduleKeys.GID];
        scheduleModel._active = result[ScheduleKeys.ACTIVE];
        scheduleModel._scheduleStatus = result[ScheduleKeys.STATUS];
        return scheduleModel;
    }

    toJSONScheduleDetails(details) {
        let data = {};
        data[ScheduleKeys.SCHEDULE_NAME] = details._scheduleName;
        data[ScheduleKeys.START_TIME] = details._startTime;
        data[ScheduleKeys.STOP_TIME] = details._stopTime;
        data[ScheduleKeys.REPEAT_MODE] = details._repeatMode;
        data[ScheduleKeys.REPEAT_TIME] = details._repeatTime;

        return data;
    }

}

const responseSetters = new ResponseSetters();
Object.freeze(responseSetters);
export default responseSetters;
import React, { useEffect, useState } from "react";
import { strings } from "../../locale/locale-properties";
import { ImageViewUtils } from "../ui-comp/img-view-utils";
import { TextViewUtils } from "../ui-comp/text-view-utils";
export function NoDataFound(props)
{
    return (
    <div className='card app_division_center_with_absolute'>
            <div className='app_column_flex card-container' style={{justifyContent: "center", height: '100%'}}>
                <div className='app_row_flex_item'>
                    <div className="app_column_flex app_row_flex_item">
                        <ImageViewUtils
                            isLocal="true"
                            imageName='nodatafound.png'
                            className="app_smaller_icon app_align_self_center"
                            />
                        <TextViewUtils 
                            className='app_smaller_text app_text_bold app_items_top_margin' 
                            text={strings.no_data_found}/>
                    </div>
                </div>
            </div>
    </div>);
}
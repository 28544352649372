import React, { useEffect, useState } from "react";
import { strings } from "../../locale/locale-properties";
import { ButtonUtils } from "../ui-comp/buttom-view-utils";
import { ImageViewUtils } from "../ui-comp/img-view-utils";
import { TextViewUtils } from "../ui-comp/text-view-utils";

export function TextRoundButton(props) {
    let text = props.text;

    return (
        <div className='app_division_with_absolute'>
            <div className='app_column_flex card-container' style={{ justifyContent: "center", height: '100%', width: '100%' }}>
                <div className='app_row_flex_item'>
                    <div className="app_column_flex">
                        <div className="app_row_flex_item">
                            <ImageViewUtils className="app_small_icon" imageName='create_icons.png' />
                        </div>
                        <div>
                            <form onSubmit={props.callback}>
                                <ButtonUtils
                                    inputType="submit"
                                    title={text}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}
export class FilterKeys 
{
    static DATE = "date";
    static WIDGET_RUNNING_TIME_BY_MOBILE_APP = "widget_running_time_by_mobile_app";
    static WIDGET_RUNNING_TIME_BY_SCHEDULE = "widget_running_time_by_schedule";
    static WIDGET_RUNNING_TIME_BY_MANUAL = "widget_running_time_by_manual";

    static WIDGET_AVERAGE_TEMPERATURE = "widget_average_temperature";
    static WIDGET_AVERAGE_VIBRATION = "widget_average_vibration";
    static WIDGET_TOTAL_PHASE_ALERTS = "widget_total_phase_alerts";
    static WIDGET_TOTAL_CRITICAL_ALERTS = "widget_total_critical_alerts";
    static WIDGET_TOTAL_RUNTIME = "widget_total_runtime";
}
import React, { useState } from "react";
import { strings } from "../../../../locale/locale-properties";
import { ImageViewUtils } from "../../../../utils/ui-comp/img-view-utils";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
import { getAlertDetails } from "../../../../utils/ui-utils/alert-utils";
export function AlertView(props) {
    let alert = props.alert;
    console.log(alert);
    let alertStatus = getAlertDetails(alert._alertLevel);
    console.log(alertStatus);
    return (<div class="card">
        <div class="card-container app_column_flex">
            <TextViewUtils
                className="app_smaller_text app_text_bold"
                text={strings.motor_name}
            />
            <TextViewUtils
                className="app_medium_text app_text_bold app_margin_top_5"
                text={alert._motorName}
            />

            <TextViewUtils
                className="app_smaller_text app_text_bold app_margin_top_10"
                text={strings.alert_name}
            />
            <TextViewUtils
                className="app_medium_text app_text_bold app_margin_top_5"
                text={alert._alertName}
            />

            <div className="app_margin_top_10 app_row_flex">
                <div className="app_row_flex app_flex_item_1">
                    <ImageViewUtils
                        isLocal="true"
                        isSvg="true"
                        imageName={alertStatus[1]}
                        className="app_smaller_icon app_align_self_center"
                    />
                    <TextViewUtils
                        className="app_smaller_text app_margin_default_left  app_align_self_center"
                        text={alertStatus[0]}
                    />
                </div>
                <div className="app_flex_item_1">
                    <TextViewUtils
                        className="app_smaller_text app_align_self_center"
                        text={alert._alertTime}
                    />
                </div>
            </div>

            <div className="app_margin_top_10 app_column_flex">
                <TextViewUtils
                    className="app_smaller_text app_text_bold"
                    text={strings.message}
                />
                <TextViewUtils
                    className="app_smaller_text app_margin_top_5"
                    text={alert._displayMsg}
                />
            </div>

            <div className="app_margin_top_10 app_column_flex">
                <TextViewUtils
                    className="app_smaller_text app_text_bold"
                    text={strings.information}
                />
                <TextViewUtils
                    className="app_smaller_text app_margin_top_5"
                    text={alert._alertValue}
                />
            </div>
        </div>
    </div>);
}
export class AlertKeys
{
    static ALERT_TABLE_NAME = "alert_notifications";

    static ALERT_ID = "alert_id";
    static ALERT_NAME = "alert_name";
    static ALERT_VALUE = "alert_value";
    static DISPLAY_MSG = "display_msg";
    static ALERT_LEVEL = "alert_level";
    static ALERT_TIME = "alert_time";
    static MID = "mid";
    static GID = "gid";
    static DATE_ADDED = "date_added";
    static VIEW_STATUS = "view_status";
    static ALERT_REFERENCE_TABLE = "alert_reference_table";
    static ALERT_REFERENCE_TABLE_FIELD = "alert_reference_table_field";
    static ALERT_REFERENCE_ID = "alert_reference_id";
    static FARMER_USER_ID = "farmer_user_id";
}
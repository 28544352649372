import React, { useState } from "react";
export default function SelectBoxUtils(props)
{
    let options = props.options;

    let selectedItem = props.selectedItem;

    let optionsList = props.options.map(function({data, key}){
        return <option style={{padding: 5}} key={key} value={key}>{data}</option>
    });

    return <select style={{padding: 5}} onChange={e=>props.onChangeValue(props.callbackWithKey, e.target.value)}>
      {optionsList}
    </select>
}
export class AlertModel
{
    get alertId()
    {
        return this._alertId;
    }
    set alertId(alertId)
    {
        this._alertId = alertId;
    }

    get alertName()
    {
        return this._alertName;
    }
    set alertName(alertName)
    {
        this._alertName = alertName;
    }

    get alertValue()
    {
        return this._alertValue;
    }
    set alertValue(alertValue)
    {
        this._alertValue = alertValue;
    }

    get displayMsg()
    {
        return this._displayMsg;
    }
    set displayMsg(displayMsg)
    {
        this._displayMsg = displayMsg;
    }

    get alertLevel()
    {
        return this._alertLevel;
    }
    set alertLevel(alertLevel)
    {
        this._alertLevel = alertLevel;
    }

    get alertTime()
    {
        return this._alertTime;
    }
    set alertTime(alertTime)
    {
        this._alertTime = alertTime;
    }

    get mid()
    {
        return this._mid;
    }
    set mid(mid)
    {
        this._mid = mid;
    }

    get gid()
    {
        return this._gid;
    }
    set gid(gid)
    {
        this._gid = gid;
    }

    get dateAdded()
    {
        return this._dateAdded;
    }
    set dateAdded(dateAdded)
    {
        this._dateAdded = dateAdded;
    }

    get viewStatus()
    {
        return this._viewStatus;
    }
    set viewStatus(viewStatus)
    {
        this._viewStatus = viewStatus;
    }

    get alertReferenceTable()
    {
        return this._alertReferenceTable;
    }
    set alertReferenceTable(alertReferenceTable)
    {
        this._alertReferenceTable = alertReferenceTable;
    }

    get alertReferenceTableField()
    {
        return this._alertReferenceTableField;
    }
    set alertReferenceTableField(alertReferenceTableField)
    {
        this._alertReferenceTableField = alertReferenceTableField;
    }

    get alertReferenceId()
    {
        return this._alertReferenceId;
    }
    set alertReferenceId(alertReferenceId)
    {
        this._alertReferenceId = alertReferenceId;
    }

    get farmerUserId()
    {
        return this._farmerUserId;
    }
    set farmerUserId(farmerUserId)
    {
        this._farmerUserId = farmerUserId;
    }
    get motorName()
    {
        return this._motorName;
    }
    set motorName(motorName)
    {
        this._motorName = motorName;
    }
}
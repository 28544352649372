export class MotorModel
{
    get motorId()
    {
        return this._motorId;
    }

    get motorName()
    {
        return this._motorName;
    }

    get motorLocationLat()
    {
        return this._motorLocationLat;
    }

    get motorLocationLang()
    {
        return this._motorLocationLang;
    }

    get motorType()
    {
        return this._motorType;
    }

    get motorSerialNumber()
    {
        return this._motorSerialNumber;
    }

    get motorDatePurchased()
    {
        return this._motorDatePurchased;
    }

    get motorWarranty()
    {
        return this._motorWarranty;
    }

    get motorPowerConsumption()
    {
        return this._motorPowerConsumption;
    }

    get motorNote()
    {
        return this._motorNote;
    }

    get motorAreaCovers()
    {
        return this._motorAreaCovers;
    }

    get motorBrand()
    {
        return this._motorBrand;
    }

    get dateAdded()
    {
        return this._dateAdded;
    }

    get lastModified()
    {
        return this._lastModified;
    }

    get motorStatusUpdated()
    {
        return this._motorStatusUpdated;
    }

    get farmerLandCropFieldsId()
    {
        return this._farmerLandCropFieldsId;
    }

    get motorStatus()
    {
        return this._motorStatus;
    }

    get motorTotalRunTime()
    {
        return this._motorTotalRunTime;
    }

    get motorStatusOperator()
    {
        return this._motorStatusOperator;
    }

    get motorStatusOperatedBy()
    {
        return this._motorStatusOperatedBy;
    }

    get motorUpdateTable()
    {
        return this._motorUpdateTable;
    }

    get motorUpdatePayload()
    {
        return this._motorUpdatePayload;
    }

    get active()
    {
        return this._active;
    }

    set motorId(motorId)
    {
        this._motorId = motorId;
    }

    set motorName(motorName) {
        this._motorName = motorName;
    }

    set motorLocationLat(motorLocationLat) {
        this._motorLocationLat = motorLocationLat;
    }

    set motorLocationLang(motorLocationLang) {
        this._motorLocationLang = motorLocationLang;
    }

    set motorType(motorType) {
        this._motorType = motorType;
    }

    set motorSerialNumber(motorSerialNumber) {
        this._motorSerialNumber = motorSerialNumber;
    }

    set motorDatePurchased(motorDatePurchased) {
        this._motorDatePurchased = motorDatePurchased;
    }

    set motorWarranty(motorWarranty) {
        this._motorWarranty = motorWarranty;
    }

    set motorPowerConsumption(motorPowerConsumption) {
        this._motorPowerConsumption = motorPowerConsumption;
    }

    set motorNote(motorNote) {
        this._motorNote = motorNote;
    }

    set motorAreaCovers(motorAreaCovers) {
        this._motorAreaCovers = motorAreaCovers;
    }

    set motorBrand(motorBrand) {
        this._motorBrand = motorBrand;
    }

    set farmerLandCropFieldsId(farmerLandCropFieldsId) {
        this._farmerLandCropFieldsId = farmerLandCropFieldsId;
    }

    set dateAdded(dateAdded) {
        this._dateAdded = dateAdded;
    }

    set lastModified(lastModified) {
        this._lastModified = lastModified;
    }

    set motorStatusUpdated(motorStatusUpdated) {
        this._motorStatusUpdated = motorStatusUpdated;
    }

    set motorStatus(motorStatus) {
        this._motorStatus = motorStatus;
    }

    set motorTotalRunTime(motorTotalRunTime) {
        this._motorTotalRunTime = motorTotalRunTime;
    }

    set motorStatusOperatedBy(motorStatusOperatedBy) {
        this._motorStatusOperatedBy = motorStatusOperatedBy;
    }

    set motorUpdateTable(motorUpdateTable) {
        this._motorUpdateTable = motorUpdateTable;
    }

    set motorUpdatePayload(motorUpdatePayload) {
        this._motorUpdatePayload = motorUpdatePayload;
    }

    set motorStatusOperator(motorStatusOperator) {
        this._motorStatusOperator = motorStatusOperator;
    }
    set active(active) {
        this._active = active;
    }
} 
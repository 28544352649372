import React from "react";

export function TextBoxUtils(props) {
  let placeholder = props.placeholder;
  let value = props.value;
  let onChangeValue = props.onChangeValue;
  let inputType = props.inputType == undefined ? 'text' : props.inputType;

  if(props.callbackWithKey!=undefined)
  {
    return (
      <input
        className="app_text_input_field"
        type={inputType}
        placeholder={placeholder}
        value={value}
        name='ddd'
        onChange={e=>onChangeValue(props.callbackWithKey, e.target.value)}
      />
    );
  }

  return (
    <input
      className="app_text_input_field"
      type={inputType}
      placeholder={placeholder}
      value={value}
      onChange={e=>onChangeValue(e.target.value)}
    />
  );
}

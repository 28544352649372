import { ErrorConstants } from "../../constants/error-constants";
import { strings } from "../../locale/locale-properties";

class ErrorUtils
{

    constructor()
    {
        if(!ErrorUtils.instance)
        {
            ErrorUtils.instance = this;
        }
        return ErrorUtils.instance;
    }

    getErrorMessage(code)
    {
        let message = '';
        if(code == ErrorConstants.INVALID_USER)
        {
            message = strings.invalid_user;
        }
        else if(code == ErrorConstants.INVALID_TOKEN)
        {
            message = strings.invalid_token;
        }
        else if(code == ErrorConstants.INVALID_OTP)
        {
            message = strings.invalid_otp;
        }
        else if(code == ErrorConstants.NETWORK_ERROR)
        {
            message = strings.network_error;
        }
        else if(code == ErrorConstants.UNKNOWN_ERROR)
        {
            message = strings.network_error;
        }
        else if(code == ErrorConstants.SCHEDULE_ALREADY_EXISTS)
        {
            message = strings.schedule_already_exists;
        }
        else if(code == ErrorConstants.SHCEDULE_INVALID_DETAILS)
        {
            message = strings.schedule_details_invalid;
        }
        return message;
    }

}

const errorUtils = new ErrorUtils();
Object.freeze(errorUtils);
export default errorUtils;
import { strings } from "../../locale/locale-properties";
import warning_yellow from "../../assets/svg/warning_yellow.svg";
import success_green from "../../assets/svg/success_green.svg";
import critical_red from "../../assets/svg/critical_red.svg";

export function getAlertDetails(alertLevel)
{
    var arr = [];
    if(alertLevel == 1)
    {
        arr[0]=strings.alert_warning;
        arr[1]=warning_yellow;
    }
    else if(alertLevel==2)
    {
        arr[0]=strings.alert_success;
        arr[1]=success_green;
    }
    else if(alertLevel==3)
    {
        arr[0]=strings.alert_critical;
        arr[1]=critical_red;
    }
    else
    {
        arr[0]=strings.alert_danger;
        arr[1]=critical_red;
    }
    return arr;
}
export class ScheduleModel
{
    get scheduleId()
    {
        return this._scheduleId;
    }
    set scheduleId(scheduleId)
    {
        this._scheduleId = scheduleId;
    }

    get scheduleName()
    {
        return this._scheduleName;
    }
    set scheduleName(scheduleName)
    {
        this._scheduleName = scheduleName;
    }

    get scheduleDescription()
    {
        return this._scheduleDescription;
    }
    set scheduleDescription(scheduleDescription)
    {
        this._scheduleDescription = scheduleDescription;
    }

    get startTime()
    {
        return this._startTime;
    }
    set startTime(startTime)
    {
        this._startTime = startTime;
    }

    get stopTime()
    {
        return this._stopTime;
    }
    set stopTime(stopTime)
    {
        this._stopTime = stopTime;
    }

    get repeatMode()
    {
        return this._repeatMode;
    }
    set repeatMode(repeatMode)
    {
        this._repeatMode = repeatMode;
    }

    get repeatTime()
    {
        return this._repeatTime;
    }
    set repeatTime(repeatTime)
    {
        this._repeatTime = repeatTime;
    }

    get days()
    {
        return this._days;
    }
    set days(days)
    {
        this._days = days;
    }

    get dateAdded()
    {
        return this._dateAdded;
    }
    set dateAdded(dateAdded)
    {
        this._dateAdded = dateAdded;
    }

    get lastModified()
    {
        return this._lastModified;
    }
    set lastModified(lastModified)
    {
        this._lastModified = lastModified;
    }

    get active()
    {
        return this._active;
    }
    set active(active)
    {
        this._active = active;
    }

    get mid()
    {
        return this._mid;
    }
    set mid(mid)
    {
        this._mid  = mid;
    }

    get gid()
    {
        return this._gid;
    }
    set gid(gid)
    {
        this._gid = gid;
    }

    get scheduleStatus()
    {
        return this._scheduleStatus;
    }
    set scheduleStatus(scheduleStatus)
    {
        this._scheduleStatus = scheduleStatus;
    }
}
import React, { useState } from "react";
import { NavBarConstants } from "../../../constants/app-nav-bar-constants";
import { NavBarComp } from "../../../utils/ui-comp/nav-bar-comp";
import { getNavId } from "../../../utils/ui-utils/navbar-utils";
import ScheduleDetails from "./ScheduleDetails";

export function Schedule(props)
{
    const currentPage = getNavId(NavBarConstants.MAIN_NAV_BAR, "/home/schedule");
    console.log('h')

    return (
        <div style={{ width: "100%", height: "100%"}}>
          <div className='app_column_flex app_division' style={{height: '100%'}}>
            <div className="">
              <NavBarComp
                navList={NavBarConstants.MAIN_NAV_BAR}
                active={currentPage}/>
            </div>
            <div className="row" style={{height: '100%', overflowY: 'scroll'}}>
                <ScheduleDetails/>
            </div>
          </div>
        </div>
      );
}
class ResponseUtils
{
    constructor()
    {
        if(!ResponseUtils.instance)
        {
            ResponseUtils.instance = this;
        }
        return ResponseUtils.instance;
    }

   validateResponse(response)
   {
       if(response['success'] == true)
       {
           return true;
       }
       return false;
   }
   
   getSuccessResponse(response)
   {
       return response['data'];
   }

   getFailureResponseCode(response)
   {
       return response['errCode'];
   }
}

const responseUtils = new ResponseUtils();
Object.freeze(responseUtils);
export default responseUtils;
import React, { useEffect, useState } from "react";
import { LocalStorageConstants } from "../../../../constants/local-storage-constants";
import { MotorKeys } from "../../../../constants/motor-keys/motor-keys";
import { NoDataFound } from "../../../../utils/common-comp/no-data-found";
import ajaxUtil from "../../../../utils/network/ajax-util";
import responseUtils from "../../../../utils/network/response-util";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { ListViewComp } from "../../../../utils/ui-comp/list-view-comp";
export function MotorList(props) {
  let [isLoading, setLoading] = useState(true);
  let [motorList, setMotorList] = useState([]);

  let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

  function callbackFunction(result) {
    if (responseUtils.validateResponse(result)) {
      let data = responseUtils.getSuccessResponse(result);
      // console.log(data);
      let motors = motorList;
      for (let pos = 0; pos < data.length; pos++) {
        let motor = responseSetters.setMotorDetails(data[pos]);
        //  console.log(motor)
        motors.push(
          <ListViewComp
            id={motor._motorId}
            key={pos}
            title={motor._motorName}
            info={motor._motorBrand}
            icon="motor.png"
            callback={onClickList}
          />
        );
      }
      setMotorList(motors);
      setLoading(false);
    }
  }

  function onClickList(motorId) {
    console.log(motorId);
    props.callback(motorId);
  }

  useEffect(() => {
    ajaxUtil.asyncAjax(
      "api/user/" + userId + "/motor",
      "GET",
      "",
      callbackFunction
    );
  }, []);

  let componetDetails;
  if (isLoading) {
    componetDetails = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
  }
  else {
    if (motorList.length > 0) {
      componetDetails = <div>{motorList}</div>;
    }
    else {
      componetDetails = <NoDataFound />;
    }
  }

  return (
    <div style={{ width: "100%", height: "100%", position: "relative", padding: 10 }}>
      {componetDetails}
    </div>
  );
}

class CommonUtils
{
    constructor()
    {
        if(!CommonUtils.instance)
        {
            CommonUtils.instance = this;
        }
        return CommonUtils.instance;
    }

    get24HrsTime(hr, period)
    {
        if ("AM" == (period))
        {
            return hr;
        }
        else
        {
            let hour = parseInt(hr);
            return  12+hour+"";
        }
    }
}

const commonUtils = new CommonUtils();
Object.freeze(commonUtils);
export default commonUtils;

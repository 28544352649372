import React, { useState } from "react";
import { ImageViewUtils } from "./img-view-utils";
import { TextViewUtils } from "./text-view-utils";
export function ListViewComp(props)
{

    function onItemClick(id)
    {
       props.callback(id);
    }

    return (<div class="card">
    <div class="card-container app_row_flex" onClick={()=>onItemClick(props.id)}>
            <ImageViewUtils
                isLocal="true"
                imageName={props.icon}
                className="app_smaller_icon app_row_flex_item"
              />
        <div className='app_row_flex_item app_margin_default_left'>
            <TextViewUtils className='app_small_text app_text_bold' text={props.title}/>
        </div>
        <div className='app_row_flex_item app_margin_default_left'>
            <TextViewUtils className='app_smaller_text' text={props.info}/>
        </div>
    </div>
  </div>
    );
}
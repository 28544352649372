import { ROOT_URL } from "../../constants/url-constants";
import { ErrorConstants } from "../../constants/error-constants";
import { LocalStorageConstants } from "../../constants/local-storage-constants";

class AjaxUtil {

    constructor() {
        if (!AjaxUtil.instance) {
            AjaxUtil.instance = this;
        }
        return AjaxUtil.instance;
    }


    async synAjax(url, method, data) {
        url = ROOT_URL + url;

        if (method != 'GET') {
            try {
                let response = await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),

                });

                let result = response.json();

                return Promise.resolve(result);
            }
            catch (error) {
                try {
                    if (error == 'TypeError: Failed to fetch') {
                        let res = {};
                        res['success'] = false;
                        res['errCode'] = ErrorConstants.NETWORK_ERROR;
                        return res;
                    }
                    return Promise.reject(error);
                }
                catch (err) {
                    return Promise.reject(err);
                }
            }
        }
        else {
            try {
                let response = await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json"
                    }

                });

                let result = response.json();

                return Promise.resolve(result);
            }
            catch (error) {
                try {
                    if (error == 'TypeError: Failed to fetch') {
                        let res = {};
                        res['success'] = false;
                        res['errCode'] = ErrorConstants.NETWORK_ERROR;
                        return res;
                    }
                    return Promise.reject(error);
                }
                catch (err) {
                    return Promise.reject(err);
                }
            }
        }
    }

    asyncAjax(url, method, data, callback) {

        url = ROOT_URL + url;

        // console.log(url);
        if (method != 'GET') {

            console.log(data)

            fetch(url,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: method,
                    body: JSON.stringify(data),

                }).then((json) => {
                    json.json().then((data) => 
                    {

                        callback(data);
                    });
                }).catch((error) => {
                    try {
                        if (error == 'TypeError: Failed to fetch') {
                            let res = {};
                            res['success'] = false;
                            res['errCode'] = ErrorConstants.NETWORK_ERROR;
                            callback(res);
                        }
                        callback(error);
                    }
                    catch (err) {
                        callback(err);
                    }
                });
        }

        else {
            fetch(url,
                {
                    method: method,

                }).then((json) => {
                    json.json().then((data) => {
                        callback(data);
                    });
                }).catch((error) => {
                    try {
                        if (error == 'TypeError: Failed to fetch') {
                            let res = {};
                            res['success'] = false;
                            res['errCode'] = ErrorConstants.NETWORK_ERROR;
                            callback(res);
                        }
                        callback(error);
                    }
                    catch (err) {
                        callback(err);
                    }
                });
        }


    }
}

const ajaxUtil = new AjaxUtil();
Object.freeze(ajaxUtil);
export default ajaxUtil;


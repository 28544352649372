export class FarmerAppUsersKeys
{
    // FarmerAppUsers
    static FARMER_APP_USER_TABLE_NAME = "farmer_app_users";
    static FARMER_USER_ID = "farmer_user_id";
    static FARMER_EMAIL = "farmer_email";
    static FARMER_PHONE = "farmer_phone";
    static FARMER_USERNAME = "farmer_username";
    static FARMER_IMEI = "farmer_imei";
    static FARMER_PASSWORD = "password";
    static FARMER_PRIVATE_KEY_PATH = "farmer_private_key_path";
    static DATE_ADDED = "date_added";
    static LAST_MODIFIED = "last_modified";
    static SESSION_ACTIVE = "session_active";
    static SESSION_KEY = "session_key";
    static ACTIVE = "active";
    static FARMERS_FARMER_ID = "farmers_farmer_id";
}
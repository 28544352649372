export class WidgetModel
{
    get date() {
        return this._date;
    }

    set date(date) {
        this._date = date;
    }

    get widgetRunningTimeByMobileApp() {
        return this._widgetRunningTimeByMobileApp;
    }

    set widgetRunningTimeByMobileApp(widgetRunningTimeByMobileApp) {
        this._widgetRunningTimeByMobileApp = widgetRunningTimeByMobileApp;
    }

    get widgetRunningTimeBySchedule() {
        return this._widgetRunningTimeBySchedule;
    }

    set widgetRunningTimeBySchedule(widgetRunningTimeBySchedule) {
        this._widgetRunningTimeBySchedule = widgetRunningTimeBySchedule;
    }

    get widgetRunningTimeByManual() {
        return this._widgetRunningTimeByManual;
    }

    set widgetRunningTimeByManual(widgetRunningTimeByManual) {
        this._widgetRunningTimeByManual = widgetRunningTimeByManual;
    }

    get widgetTotalCriticalAlerts() {
        return this._widgetTotalCriticalAlerts;
    }

    set widgetTotalCriticalAlerts(widgetTotalCriticalAlerts) {
        this._widgetTotalCriticalAlerts = widgetTotalCriticalAlerts;
    }

    get widgetTotalPhaseAlerts() {
        return this._widgetTotalPhaseAlerts;
    }

    set widgetTotalPhaseAlerts(widgetTotalPhaseAlerts) {
        this._widgetTotalPhaseAlerts = widgetTotalPhaseAlerts;
    }

    get widgetAverageTemperature() {
        return this._widgetAverageTemperature;
    }

    set widgetAverageTemperature(widgetAverageTemperature) {
        this._widgetAverageTemperature = widgetAverageTemperature;
    }

    get widgetAverageVibration() {
        return this._widgetAverageVibration;
    }

    set widgetAverageVibration(widgetAverageVibration) {
        this._widgetAverageVibration = widgetAverageVibration;
    }

    get widgetTotalRuntime() {
        return this._widgetTotalRuntime;
    }

    set widgetTotalRuntime(widgetTotalRuntime) {
        this._widgetTotalRuntime = widgetTotalRuntime;
    }
}
export class ScheduleKeys
{
    static SCHEDULE_TABLE_NAME = "motor_schedules"; 
    static MOTOR_SCHEDULE_ID = "motor_schedule_id";
    static SCHEDULE_NAME = "schedule_name";
    static SCHEDULE_DESCRIPTION = "schedule_description";
    static START_TIME = "start_time";
    static STOP_TIME = "stop_time";
    static REPEAT_MODE = "repeat_mode";
    static REPEAT_TIME = "repeat_time";
    static DAYS = "days";
    static DATE_ADDED = "date_added";
    static LAST_MODIFIED = "last_modified";
    static MID = "mid";
    static GID = "gid";
    static ACTIVE = "active";
    static STATUS = "status";
}
import React, { useState } from "react";
import { strings } from "../locale/locale-properties";
import { Login } from "./login/Login";
import "../styles/Main.css";
import { BrowserRouter, Route, Link, Switch, useHistory, Router, HashRouter } from "react-router-dom";
import { createBrowserHistory } from 'history'
import { Dashboard } from "./home/dashboard/Dashboard";
import { Home } from "./home/Home";
import { AlertConstants } from "../constants/alert-constants";
import { Motor } from "./home/motor/Motor";

export function Main() {
  const [alert, setAlert] = useState("app_alert app_item_hide");
  const [progress, setProgress] = useState("app_loader app_item_hide");
  const [showAppContents, setShowAppContents] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const history = createBrowserHistory();

  function closeAlert() 
  {
    setAlert("app_alert app_item_hide");
  }
  function showAlert(message, alertType)
  {
    if(alertType == AlertConstants.SUCCESS)
    {
      alertType = 'green-background-color'
    }
    else if(alertType == AlertConstants.FAILED)
    {
      alertType = 'red-background-color'
    }

    setAlert("app_alert " + alertType);
    setAlertMessage(message);
  }
  function showProgress()
  {
    setProgress("app_loader");
    setShowAppContents("app_contents_disabled");
  }
  function dismissProgress()
  {
    setProgress("app_loader app_item_hide");
    setShowAppContents("");
  }

  return (
    <div className="app_container">
      
      <div id="appLoader" className={progress}></div>

      <div class={alert} id="app_alert_view">
        <span class="app_alert_closebtn" onClick={closeAlert}>
          &times;
        </span>
        <span className="app_alert_content app_smaller_text">{alertMessage}</span>
      </div>

      <div id="appContents" className={showAppContents}>

        <HashRouter history={history} >
          <Switch>
            <Route exact path="/">
              <Login showAlert={showAlert} showProgress={showProgress} dismissProgress={dismissProgress}/>
            </Route>
            <Route path="/home">
            <Home/>
              {/* <Route>
                <Route exact path="/home">
                  <Home/>
                </Route>
                <Route path="/home/motor">
                  <Motor/>
                </Route>
              </Route> */}
            </Route>
          </Switch>
        </HashRouter>
      </div>
    </div>
  );
}

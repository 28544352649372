import React, { useState } from "react";
import { strings } from "../../../../locale/locale-properties";
import { NoDataFound } from "../../../../utils/common-comp/no-data-found";
import { TextViewUtils } from "../../../../utils/ui-comp/text-view-utils";
export function MotorDetails(props)
{
    let motor=props.motor;
    if(motor==undefined)
    {
      return (<div>
        <NoDataFound/>
      </div>);
    }
    return(<div class="card green-background-color">
    <div class="card-container app_column_flex">
      <TextViewUtils
        className="app_text_white app_medium_text app_text_bold"
        text={motor._motorName}
      />
        <div className="app_margin_top_10 app_row_flex">
        <TextViewUtils
            className="app_small_text app_text_bold app_text_white app_flex_item_1"
            text={strings.started_at}
          />
          <TextViewUtils
            className="app_small_text app_text_white app_margin_default_left app_flex_item_1"
            text={motor._motorStatusUpdated}
          />
      </div>

      <div className="app_margin_top_10 app_row_flex">
        <TextViewUtils
            className="app_small_text app_text_bold app_text_white app_flex_item_1"
            text={strings.operator}
          />
          <TextViewUtils
            className="app_small_text app_text_white app_margin_default_left app_flex_item_1"
            text={motor._motorStatusOperator}
          />
      </div>

      <div className="app_margin_top_10 app_row_flex">
        <TextViewUtils
            className="app_small_text app_text_bold app_text_white app_flex_item_1"
            text={strings.operation_mode}
          />
          <TextViewUtils
            className="app_small_text app_text_white app_margin_default_left app_flex_item_1"
            text={motor._motorStatusOperatedBy}
          />
      </div>

    </div>
  </div>);
}
import React, { useEffect, useState } from "react";
import { FilterConstants } from "../../../../constants/filter-constants";
import { LocalStorageConstants } from "../../../../constants/local-storage-constants";
import ajaxUtil from "../../../../utils/network/ajax-util";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import responseUtils from "../../../../utils/network/response-util";
import responseSetters from "../../../../utils/response-setters/response-setters";
import { ListViewComp } from "../../../../utils/ui-comp/list-view-comp";


export default function AlertList(props)
{
  let userId = localStorage.getItem(LocalStorageConstants.USER_ID);
    function genFilter(offset, limit, orderBy, order, filter)
    {
        return  FilterConstants.OFFSET+'='+offset+'&'+FilterConstants.ORDER+'='+order+'&'+FilterConstants.LIMIT+'='+limit+'&'+FilterConstants.ORDER_BY+'='+orderBy+'&'+FilterConstants.FILTER+'='+filter;
    }

    const [isLoading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [motorId, setMotorId] = useState();

    if(motorId !== props.motorId && props.motorId > 0)
    {
      getAlerts(0, 1000000, 'alert_notifications.alert_time', 'asc', '1=1');
    }


    

    function callbackFunction(result) 
    {
        console.log(result);
        if(responseUtils.validateResponse(result))
        {
            let itemList = [];
            let data = responseUtils.getSuccessResponse(result);

            for (let pos = 0; pos < data.length; pos++) 
            {
                let alert = responseSetters.setAlertDetails(data[pos]);
                itemList.push(
                    <ListViewComp
                      id={alert._alertId}
                      key={pos}
                      title={alert._alertName}
                      info={alert._alertTime}
                      icon="motor.png"
                      callback={onClickList}
                    />
                  );
            }
            setMotorId(props.motorId);
            setItems(itemList);
            setLoading(false);
        }
    }
    function onClickList(alertId)
    {
        console.log(alertId);
        props.callback(alertId);
    }

    function getAlerts(offset, limit, orderBy, order, filter)
    {
        ajaxUtil.asyncAjax(
            "api/user/" + userId + "/motor/"+props.motorId+"/alerts?"+genFilter(offset, limit, orderBy, order, filter),
            "GET",
            "",
            callbackFunction
          );
    }

    useEffect(()=>
     {
        
      },[]);

      let componetDetails;
      if(isLoading)
      {
         componetDetails = <div className="app_comp_loader" style={{ position: "absolute" }}></div>;
      }
      else
      {
        // console.log(alertlist)
        componetDetails = <div>{items}</div>;
      }
    
        return (
          <div style={{ width: "100%", height: "100%", position: "relative", padding: 10, overflowY: 'scroll' }}>
            {componetDetails}
          </div>
        );
}
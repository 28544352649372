import { LocalStorageConstants } from "../../../constants/local-storage-constants";
import responseSetters from "../../response-setters/response-setters";
import ajaxUtil from "../ajax-util";
import responseUtils from "../response-util";

class ScheduleNetworkUtils {
    userId = localStorage.getItem(LocalStorageConstants.USER_ID);

    constructor() {
        if (!ScheduleNetworkUtils.instance) {
            ScheduleNetworkUtils.instance = this;
        }
        return ScheduleNetworkUtils.instance;
    }

    getAllSchedules(filter, motorId, callbackFunction)
    {
        ajaxUtil.asyncAjax("api/user/" + this.userId + "/motor", "GET", "", callbackFunction);
    }

    async createOrEditSchedule(motorId, inputData)
    {
        return await ajaxUtil.synAjax("api/user/" + this.userId + "/motor/"+motorId+"/schedule", "POST", inputData);
    }

}

const scheduleNetworkUtils = new ScheduleNetworkUtils();
Object.freeze(scheduleNetworkUtils);
export default scheduleNetworkUtils;
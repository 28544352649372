import React, { useState } from "react";
import ScrollArea from 'react-scrollbar';
import { Redirect } from "react-router";
import { NavBarConstants } from "../../../constants/app-nav-bar-constants";
import { LocalStorageConstants } from "../../../constants/local-storage-constants";
import { ListViewComp } from "../../../utils/ui-comp/list-view-comp";
import { NavBarComp } from "../../../utils/ui-comp/nav-bar-comp";
import { getNavId } from "../../../utils/ui-utils/navbar-utils";
import { MotorList } from "./childcomp/MotorList";
import { MotorStatus } from "./MotorStatus";

export function Motor(props) {
  const currentPage = getNavId(NavBarConstants.MAIN_NAV_BAR, "/home/motor");
  let [motorId, setMotorId] = useState(0);
  
  function motorListOnClickComponent(mid)
  {
    // console.log(mid);
    setMotorId(mid);
  }

  return (
    <div style={{ width: "100%", height: "100%"}}>
      <div className='app_column_flex app_division' style={{height: '100%'}}>
        <div className="">
          <NavBarComp
            navList={NavBarConstants.MAIN_NAV_BAR}
            active={currentPage}
          />
        </div>
        <div className="row" style={{height: '100%', overflowY: 'scroll'}}>
          <MotorStatus/>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
export default function RadioButtonUtils(props) {
    let checkedStatus = false;
    if (props.checked === 'true') {
        checkedStatus = true;
    }
    return (
        <div className="app_row_flex">
            <input defaultChecked={checkedStatus} onChange={e=>props.onChangeValue(props.callbackWithKey, e.target.value)} className="app_align_self_center" type='radio' name={props.name} value={props.originalValue} />
            <label className={props.className + ' app_align_self_center app_margin_default_left'}>{props.value}
            </label>
        </div>);
}
import LocalizedStrings  from 'react-localization';

export let strings = new LocalizedStrings({
    en:
    {
        app_name:'NeuralArc',
        login_page_login_title_label : "Login with registered phone number",
        login_page_login_phone_number_placeholder: 'Phone number',
        login_page_login_button_title: "Login",

        login_page_otp_enter_otp_label: "Enter your one time password",
        login_page_otp_password_placeholder: "Secret code",
        login_page_otp_button_title: "Verify",
        login_page_otp_remaining_time: "Remaining time",


        // errors messages
        invalid_token: "Invalid user credentials",
        invalid_user: "Invalid user details entered",
        invalid_otp: "Invalid otp entered",
        network_error: "Please check your internet",
        unknown_error: "An unknown error has occurred",
        schedule_already_exists: "Selected time already exists",
        schedule_details_invalid: "Invalid schdedule details",

        //Keys motor status
        started_at: "Started At ",
        stopped_at: "Stopped At ",
        operator: "Operator ",
        operation_mode: "Operation Mode ",
        running: "Running",
        not_running: "Not Running",

        // no data found
        no_data_found: "No Data Found",
        motor_name: "Motor Name",

        //motor status change
        click_button_to_change_motor_status: "Click below button to change your motor status",
        motor_status_button_on: "ON",
        motor_status_button_off: "OFF",

        //Widgets
        motor_run_by_app: 'App',
        motor_run_by_schedule: 'Schedule',
        motor_run_by_manual: 'Manual',

        motor_total_run_time: 'Total Runtime',
        motor_average_temperature: 'Average Temperature',
        motor_average_vibration: 'Average Vibration',
        motor_total_phase_alerts: 'Total Phase Critical Alerts',
        motor_total_critical_alerts: 'Total Critical Alerts',

        motor_total_runtime_title: 'Total Runtime',
        motor_all_notifications_title: 'All Notifications',

        //alerts
        motor_name: 'Motor Name',
        alert_name: 'Alert Name',
        message: 'Message',
        information: 'Info',

        alert_warning: 'Warning',
        alert_success: 'Success',
        alert_critical: 'Critical',
        alert_danger: 'Danger',
        alert_status: 'Alert Status',

        //Schedule
        schedule_create_button: 'Create New Schedule',
        schedule_enter_schedule_details: 'Enter schedule details',
        schedule_name: 'Schedule Name',
        schedule_date: 'Schedule Date',
        
        start_time: 'Start Time',
        end_time: 'End Time',
        schedule_repeat_mode: 'Repeat Mode',
        schedule_repeat_mode_every_day: 'Every Day',
        schedule_repeat_mode_every_week: 'Every Week',
        schedule_save: 'Save'

    }
});

import React, { useEffect, useState } from "react";
import ScrollArea from 'react-scrollbar';
import { LocalStorageConstants } from "../../../constants/local-storage-constants";
import { strings } from "../../../locale/locale-properties";
import { NoDataFound } from "../../../utils/common-comp/no-data-found";
import ajaxUtil from "../../../utils/network/ajax-util";
import responseUtils from "../../../utils/network/response-util";
import responseSetters from "../../../utils/response-setters/response-setters";
import { TextViewUtils } from "../../../utils/ui-comp/text-view-utils";
import MotorAllNotification from "./childcomp/MotorAllNotifications";
import { MotorDetails } from "./childcomp/MotorDetails";
import { MotorList } from "./childcomp/MotorList";
import MotorRunTime from "./childcomp/MotorRunTime";
import { MotorStatusChange } from "./childcomp/MotorStatusChange";
export function MotorStatus(props)
{
  let [isLoading, setLoading] = useState(true);
  let [isDataFound, setDataFound] = useState(true);
  let [motorStatus, setMotorStatus] = useState(false);
  let [motor, setMotor] = useState();
  

  let userId = localStorage.getItem(LocalStorageConstants.USER_ID);

  // console.log('rerendeer' )
  // console.log(motor);

  function callbackFunction(result) {
    if (responseUtils.validateResponse(result)) {
      // console.log(result);
      let data = responseUtils.getSuccessResponse(result);
      // console.log(data);
      let m = responseSetters.setMotorDetails(data);
      // console.log(m);
      setMotor(m);
      setLoading(false);
      setDataFound(true);

    } 
    else 
    {
      setDataFound(false);
    }
    // console.log(result);
  }

  // Motor list callback from MotorList component.
  function motorListCallback(mid) 
  {
    setLoading(true);
    ajaxUtil.asyncAjax(
      "api/user/" + userId + "/motor/" + mid,
      "GET",
      "",
      callbackFunction
    );
  }

  function onMotorStatusChange(event, action)
  {
    event.preventDefault();
    
    //setMotor(motor);
    
    setLoading(true);

    let body = {};
    body['motor_status'] = action;

    ajaxUtil.asyncAjax(
      "api/user/" + userId + "/motor/" + motor._motorId,
      "PATCH",
      body,
      callbackFunction
    );
  }
  
  // Initial call.
  useEffect(function () {
    setLoading(false);
    setDataFound(false);
    // setMotor(motor);
  }, []);

  let componetDetails;
  if (isLoading) {
    componetDetails = (
      <div className="app_comp_loader" style={{ position: "absolute" }}></div>
    );
  }
  else {
    if (isDataFound)
    {
      componetDetails = (
        <div style={{height: '100%'}} className='app_responsive_overflow'>
        <MotorDetails motor={motor}/>
        <div className='app_margin_top_10'>
          <MotorStatusChange motor={motor} onMotorStatusChange={onMotorStatusChange}/>
          </div>
          <div className='app_margin_top_10'>
            <MotorRunTime motor={motor}/>
          </div>
          <div className='app_margin_top_10'>
            <MotorAllNotification motor={motor}/>
          </div>
        </div>
      );
    }
    else
    {
      componetDetails = <NoDataFound/>;
        
    }
  }
  return (
    <div style={{ width: "100%", height: "100%", padding: 10 }}>
      <div className="row reverse_row app_division" style={{height: '100%'}}>
        <div
          className="col-lg-6 col-md-12 col-sm-12">
        <MotorList isNeedRefresh={true} callback={motorListCallback} />
        </div>
        <div
          className="col-lg-6 col-md-12 col-sm-12"
          style={{ height: "100%", position: "relative"  }}>
          {componetDetails}
        </div>
      </div>
    </div>
  );
}
